import React from 'react'
import "../css/Home.css"

export default function Footer() {
  return (
    <div>
      <footer className="footer">
            <p>&copy;2023 CodeRyders | All Rights Reserved</p>
      </footer>
 
    </div>
  )
}
